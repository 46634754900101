<template>
  <div class="container">
    <Header>
      <template v-slot:title>
        <span>{{ $t('hotelDetail') }}</span>
      </template>
    </Header>
    <div class="main">
      <div class="pictures">
        <van-swipe :autoplay="3000">
          <van-swipe-item v-for="(image, index) in hotel.images" :key="index">
            <van-image height="200" lazy-load fit="cover" :src="image" />
          </van-swipe-item>
        </van-swipe>
      </div>

      <div class="hoteldetail">
        <div class="hotelname">
          {{ hotel.name }}
        </div>

        <div class="hoteladdress">
          <span class="iconfont icon-iconlocation" />{{ hotel.address }}
        </div>

        <van-divider />

        <div class="date">
          <van-row type="flex">
            <van-col span="8" style="text-align: center;">
              <div class="arrival">
                <div>{{ $t('checkin') }}</div>
                <div @click="showDate = true">{{ arrival | formatDate }}</div>
              </div>
            </van-col>
            <van-col span="8" style="display: flex; flex-direction: row;  align-items: center; justify-content: center;">
              <div class="nights">
                {{ nights }} {{ $t('nights') }}
              </div>
            </van-col>
            <van-col span="8" style="text-align: center;">
              <div class="departure">
                <div>{{ $t('checkout') }}</div>
                <div @click="showDate = true">{{ departure | formatDate }}</div>
              </div>
            </van-col>
          </van-row>
          <van-calendar
            v-model="showDate"
            type="range"
            :formatter="formatter"
            :min-date="minDate"
            :max-date="maxDate"
            :default-date="[arrival, departure]"
            @confirm="onConfirm"
          />
        </div>

        <van-divider />

        <div class="guests">
          <van-row type="flex">
            <van-col span="12">{{ $t('guests') }}</van-col>
            <van-col span="12" style="display: flex; flex-direction: row; justify-content: flex-end;">
              <van-stepper v-model="guests" integer :min="1" :max="10" />
            </van-col>
          </van-row>
        </div>

        <div class="description">
          <div class="description-title">
            {{ $t('description') }}
          </div>
          <div class="description-detail">
            {{ hotel.description }}
          </div>
        </div>
        <van-divider />

        <div class="description">
          <div class="description-title">
            {{ $t('roomType') }}
          </div>
        </div>

        <div class="list">
          <div class="list-item" v-for="(roomType, index) of roomTypes" :key="index">
            <van-row type="flex" gutter="8">
              <van-col span="8">
                <van-swipe ref="roomTypeImg">
                  <van-swipe-item v-for="(image, idx) in roomType.images" :key="idx" @click="preview(index, roomType.images)">
                    <van-image height="100" lazy-load fit="cover" :src="image" />
                  </van-swipe-item>
                </van-swipe>
              </van-col>
              <van-col span="16">
                <div class="room-type-name">{{ roomType.name }}</div>
                <div class="breakfast">{{ roomType.breakfast }}</div>
                <div class="reserve">
                  <span class="rate">{{hotel.currency_code}}{{ roomType.rate_amount | formatNumber }}</span>
                  <van-button type="warning" size="small" @click="reserve(roomType)">{{ $t('reserve') }}</van-button>
                </div>
              </van-col>
            </van-row>

            <van-divider />
          </div>

          <van-empty v-if="roomTypes.length < 1" image="search" :description="$t('all rooms sold out')" />
        </div>

        <div class="description">
          <div class="description-title">
            {{ $t('facilities') }}
          </div>
        </div>

        <div class="list">
          <van-row>
            <van-col style="padding: 0.5rem 0;" span="12" v-for="(item ,index) of facilityList" :key="index">
              <span class="facilities">
                <svg-icon :icon-class="facilities[item]" />
                <span>{{ $t(facilities[item]) }}</span>
              </span>
            </van-col>
          </van-row>
        </div>
        <van-divider />

        <div class="description">
          <div class="description-title">
            {{ $t('cancellationPolicy') }}
          </div>
        </div>

        <ul style="padding-bottom: 10px;">
          <li v-for="(item, index) of cancellationPolicies" :key="index">
            {{ item.description }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { Notify, ImagePreview } from 'vant'
import Header from '@/components/Header'
import { formatDate } from '@/filters'
import Hotel from '@/models/hotel'
import { facilities } from '@/utils'

export default {
  name: 'HotelDetail',
  components: {
    Header
  },
  data () {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    return {
      facilities: facilities(),
      showDate: false,
      arrival: new Date(dayjs(this.$route.params.arrival).valueOf()),
      departure: new Date(dayjs(this.$route.params.departure).valueOf()),
      guests: this.$route.params.guests,
      today,
      minDate: today,
      maxDate: today,
      hotel: new Hotel(),
      roomTypes: [],
      cancellationPolicies: []
    }
  },
  computed: {
    nights () {
      if (this.arrival && this.departure) {
        return dayjs(this.departure).diff(dayjs(this.arrival), 'day')
      }

      return 0
    },
    facilityList () {
      if (this.hotel.facilities) {
        return this.hotel.facilities.split(',')
      }

      return []
    }
  },
  async mounted () {
    try {
      const res = await this.$axios.get(`/invitation/hotel-detail/${this.$route.params.hotelId}/arrival/${this.$route.params.arrival}/departure/${this.$route.params.departure}`)
      this.minDate = new Date(res.data.conference.reservation_begin_date) < this.today ? this.today : new Date(res.data.conference.reservation_begin_date)
      this.maxDate = new Date(res.data.conference.reservation_end_date)
      Object.assign(this.hotel, res.data.hotel)
      this.roomTypes = res.data.roomTypes
      this.cancellationPolicies = res.data.cancellationPolicies
    } catch (e) {
      Notify(e.message)
    }
  },
  methods: {
    formatter (day) {
      if (day.type === 'start') {
        day.bottomInfo = this.$t('in')
      } else if (day.type === 'end') {
        day.bottomInfo = this.$t('out')
      }

      return day
    },
    onConfirm (e) {
      this.arrival = e[0]
      this.departure = e[1]
      this.showDate = false
    },
    reserve (roomType) {
      if (this.nights < 2) {
        Notify(this.$t('reservationNightsMessage'))
      } else {
        this.$router.push({
          name: 'Reservation',
          params: { arrival: formatDate(this.arrival), departure: formatDate(this.departure), guests: this.guests, roomTypeId: roomType.id, hotelId: this.$route.params.hotelId }
        })
      }
    },
    preview (index, images) {
      ImagePreview({
        images,
        closeable: true,
        startPosition: this.$refs.roomTypeImg[index].active
      })
    }
  }
}
</script>

<style lang="less" scoped>
.main {
  padding-top: 64px;
}

.hoteldetail {
  padding: 0 1rem;
}

.hotelname {
  font-size: 1.3rem;
  color: #333333;
}

.hoteladdress {
  font-size: 0.9rem;
  color: #5c5c5c;
  padding-bottom: 10px;
}

.pictures {
  padding-top: 1px;
}

.date {
  padding: 20px 0;
  .nights {
    border: 1px solid #CCCCCC;
    padding: 5px 10px;
    border-radius: 50%;
    color: #7C7C7C;
  }
}

.guests {
  .van-row {
    padding-top: 10px;
  }
  padding-bottom: 10px;
}

.description {
  .description-title {
    margin: 1.6rem 0;
    font-size: 1.4rem;
  }
  .description-detail {
    font-size: 1rem;
    color: #5c5c5c;
    line-height: 1.5em;
    word-wrap: break-word;
  }
}

.room-type-name {
  margin-top: .3rem;
  font-size: 1.2rem;
}

.breakfast {
  color: #5c5c5c;
  margin-top: .3rem;
}

.reserve {
  padding: 6px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

ul {
  list-style: inside;
  li {
    color: #5c5c5c;
  }
}

li:not(:first-child) {
  margin-top: 8px;
}

.facilities {
  color: #5c5c5c;

  span:last-child {
    padding-left: 1rem;
    font-size: 1rem;
  }
}
</style>
